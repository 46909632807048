
export default {
    methods: {
        // Hiển thị chi tiết
        addTicket(listTicketRequest,listTicketLearn,listTicketEat,listTicketSleep,listTicketToilet,listTicketHealthy,
        listTicketComment,listTicketWeek,listTicketMonth,listTicketPeriodic){
            for (let i = 0; i < listTicketRequest.length; i++) {
                listTicketRequest[i].url = ''
                listTicketRequest[i].numberPicture = 0
            }
            if (listTicketLearn.length !== 0){
                listTicketRequest[0].url = listTicketLearn[0].urlPicture
                listTicketRequest[0].numberPicture = listTicketLearn.length
            }
            if (listTicketEat.length !== 0){
                listTicketRequest[1].url = listTicketEat[0].urlPicture
                listTicketRequest[1].numberPicture = listTicketEat.length
            }
            if (listTicketSleep.length !== 0){
                listTicketRequest[2].url = listTicketSleep[0].urlPicture
                listTicketRequest[2].numberPicture = listTicketSleep.length
            }
            if (listTicketToilet.length !== 0){
                listTicketRequest[3].url = listTicketToilet[0].urlPicture
                listTicketRequest[3].numberPicture = listTicketToilet.length
            }
            if (listTicketHealthy.length !== 0){
                listTicketRequest[4].url = listTicketHealthy[0].urlPicture
                listTicketRequest[4].numberPicture = listTicketHealthy.length
            }
            if (listTicketComment.length !== 0){
                listTicketRequest[5].url = listTicketComment[0].urlPicture
                listTicketRequest[5].numberPicture = listTicketComment.length
            }
            if (listTicketWeek.length !== 0){
                listTicketRequest[6].url = listTicketWeek[0].urlPicture
                listTicketRequest[6].numberPicture = listTicketWeek.length
            }
            if (listTicketMonth.length !== 0){
                listTicketRequest[7].url = listTicketMonth[0].urlPicture
                listTicketRequest[7].numberPicture = listTicketMonth.length
            }
            if (listTicketPeriodic.length !== 0){
                listTicketRequest[8].url = listTicketPeriodic[0].urlPicture
                listTicketRequest[8].numberPicture = listTicketPeriodic.length
            }
        },

        // Hiển thị danh sách ảnh trong mỗi gói
        showListImageTicketPlus(id,listTicketLearn,listTicketEat,listTicketSleep,listTicketToilet,listTicketHealthy,
                            listTicketComment,listTicketWeek,listTicketMonth,listTicketPeriodic){
            switch (id){
                case 1:{
                    return listTicketLearn;
                }
                case 2:{
                    return  listTicketEat;
                }
                case 3:{
                    return  listTicketSleep;
                }
                case 4:{
                    return  listTicketToilet;
                }
                case 5:{
                    return  listTicketHealthy;
                }
                case 6:{
                    return  listTicketComment;
                }
                case 7:{
                    return  listTicketWeek;
                }
                case 8:{
                    return  listTicketMonth;
                }
                case 9:{
                    return  listTicketPeriodic;
                }
            }
        },

    //     Lấy ra tên gói ticket
        getNameTicket(id){
            switch (id){
                case 1:{
                    return "Học tập và rèn luyện";
                }
                case 2:{
                    return  "Ăn uống";
                }
                case 3:{
                    return  "Ngủ nghỉ";
                }
                case 4:{
                    return  "Vệ sinh";
                }
                case 5:{
                    return  "Sức khỏe";
                }
                case 6:{
                    return  "Nhận xét chung";
                }
                case 7:{
                    return  "Nhận xét tuần";
                }
                case 8:{
                    return  "Nhận xét tháng";
                }
                case 9:{
                    return  "Nhận xét định kỳ";
                }
            }
        },

        // Cho ảnh vào form data trong phiếu bé ngoan
        addImageTicketFormData(formData,listTicketLearn,listTicketEat,listTicketSleep,listTicketToilet,listTicketHealthy,
                               listTicketComment,listTicketWeek,listTicketMonth,listTicketPeriodic){
            if (
                listTicketLearn !== "undefined" &&
                listTicketLearn !== null &&
                listTicketLearn.length !== null &&
                listTicketLearn.length > 0
            ) {
                listTicketLearn.forEach((element) => {
                    formData.append("listTicketLearn", element.raw);
                });
            }
            if (
                listTicketEat !== "undefined" &&
                listTicketEat !== null &&
                listTicketEat.length !== null &&
                listTicketEat.length > 0
            ) {
                listTicketEat.forEach((element) => {
                    formData.append("listTicketEat", element.raw);
                });
            }
            if (
                listTicketSleep !== "undefined" &&
                listTicketSleep !== null &&
                listTicketSleep.length !== null &&
                listTicketSleep.length > 0
            ) {
                listTicketSleep.forEach((element) => {
                    formData.append("listTicketSleep", element.raw);
                });
            }
            if (
                listTicketToilet !== "undefined" &&
                listTicketToilet !== null &&
                listTicketToilet.length !== null &&
                listTicketToilet.length > 0
            ) {
                listTicketToilet.forEach((element) => {
                    formData.append("listTicketToilet", element.raw);
                });
            }
            if (
                listTicketHealthy !== "undefined" &&
                listTicketHealthy !== null &&
                listTicketHealthy.length !== null &&
                listTicketHealthy.length > 0
            ) {
                listTicketHealthy.forEach((element) => {
                    formData.append("listTicketHealthy", element.raw);
                });
            }
            if (
                listTicketComment !== "undefined" &&
                listTicketComment !== null &&
                listTicketComment.length !== null &&
                listTicketComment.length > 0
            ) {
                listTicketComment.forEach((element) => {
                    formData.append("listTicketComment", element.raw);
                });
            }
            if (
                listTicketWeek !== "undefined" &&
                listTicketWeek !== null &&
                listTicketWeek.length !== null &&
                listTicketWeek.length > 0
            ) {
                listTicketWeek.forEach((element) => {
                    formData.append("listTicketWeek", element.raw);
                });
            }
            if (
                listTicketMonth !== "undefined" &&
                listTicketMonth !== null &&
                listTicketMonth.length !== null &&
                listTicketMonth.length > 0
            ) {
                listTicketMonth.forEach((element) => {
                    formData.append("listTicketMonth", element.raw);
                });
            }
            if (
                listTicketPeriodic !== "undefined" &&
                listTicketPeriodic !== null &&
                listTicketPeriodic.length !== null &&
                listTicketPeriodic.length > 0
            ) {
                listTicketPeriodic.forEach((element) => {
                    formData.append("listTicketPeriodic", element.raw);
                });
            }
            return formData;
        }

    }
}