<template>
  <div>
    <el-dialog
        :title="'Chi tiết: ' +  titleTicket"
        :visible.sync="showDetailTicket"
        width="1000px"
        :before-close="closeDialog"
       >
          <el-row style="
             display: flex;
             flex-wrap: wrap;
             width: 100%;
             margin-top: 10px;
             border: 1px solid rgb(96 99 102 / 19%);
             overflow-y: scroll; max-height: 600px;
             padding-left: 50px;" >
            <div v-for="item in listTicketDetail" :key="item.id"
                 style="flex: 0 0 223px; margin: 30px; width: 223px">
              <div class="inline-block-div" >
                <el-image
                    :src="item.urlPicture"
                    style="width: 100%; height: 200px"
                    @click="handleShowImage(item.urlPicture)"
                ></el-image>
              </div>
            </div>
            <el-dialog :modal="false" width="30%" :visible.sync="currentDialog.isDialogVisible">
              <img style="" width="100%" height="500px" :src="currentDialog.currentImage" alt  />
            </el-dialog>
          </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" type="danger">Đóng</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "DetailTicketDialog",
    data () {
      return {
        currentDialog: {
          currentImage: "",
          isDialogVisible: false,
        }
      }
    },
    props: {
      showDetailTicket: {
        type: Boolean,
        default: () => false,
      },
      titleTicket: {
        type: String,
        default: () => ''
      },
      listTicketDetail: {
        type: Array,
        default: () => []
      }
    },
    methods:{
      closeDialog(){
        this.$emit("close");
      },

      handleShowImage(url) {
        this.currentDialog.currentImage = url;
        this.currentDialog.isDialogVisible = true;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>