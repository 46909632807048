var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết: " + _vm.titleTicket,
            visible: _vm.showDetailTicket,
            width: "1000px",
            "before-close": _vm.closeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailTicket = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                width: "100%",
                "margin-top": "10px",
                border: "1px solid rgb(96 99 102 / 19%)",
                "overflow-y": "scroll",
                "max-height": "600px",
                "padding-left": "50px",
              },
            },
            [
              _vm._l(_vm.listTicketDetail, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticStyle: {
                      flex: "0 0 223px",
                      margin: "30px",
                      width: "223px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inline-block-div" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "200px" },
                          attrs: { src: item.urlPicture },
                          on: {
                            click: function ($event) {
                              return _vm.handleShowImage(item.urlPicture)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    modal: false,
                    width: "30%",
                    visible: _vm.currentDialog.isDialogVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(
                        _vm.currentDialog,
                        "isDialogVisible",
                        $event
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      width: "100%",
                      height: "500px",
                      src: _vm.currentDialog.currentImage,
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.closeDialog } },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }