export const plusScreenList = [
    {key: "home", value: "Trang chủ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "employee", value: "Nhân sự", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "kids", value: "Học sinh", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "message", value: "Lời nhắn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "medicine",
        value: "Dặn thuốc",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "absent", value: "Xin nghỉ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "attendance",
        value: "Điểm danh",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "evaluate",
        value: "Nhận xét",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "album", value: "Album", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "health", value: "Sức khoẻ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "learn", value: "Học tập", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "menu", value: "Thực đơn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "video", value: "Video", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "camera", value: "Camera", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "birthday",
        value: "Sinh nhật",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "studentFeesNew",
        value: "Quản lý học phí",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "studentFees",
        value: "Học phí",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "salary",
        value: "Công lương",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "cashInternal",
        value: "Thu chi",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "wallet", value: "Ví", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "news", value: "Tin tức", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "feedback", value: "Góp ý", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "smsAppHistory",
        value: "Lịch sử",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "notify", value: "Thông báo", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
]

export const teacherScreenList = [
    {key: "home", value: "Trang chủ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "contacts", value: "Danh bạ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "notify", value: "Thông báo", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "account",
        value: "Tài khoản",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "message", value: "Lời nhắn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "medicine",
        value: "Dặn thuốc",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "absent", value: "Xin nghỉ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "health", value: "Sức khoẻ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "attendance",
        value: "Điểm danh",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "album", value: "Album", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "evaluate",
        value: "Nhận xét",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "birthday",
        value: "Sinh nhật",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "video", value: "Video", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "learn", value: "Học tập", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "menu", value: "Thực đơn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "camera", value: "Camera", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "news", value: "Tin tức", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "salary",
        value: "Công lương",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "feedback", value: "Góp ý", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "smsAppHistory",
        value: "Lịch sử",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "homeInfoTeacher", value: "Thông tin giáo viên", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "headerContactTeacher", value: "Header màn danh bạ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},

]

export const parentScreenList = [
    {key: "home", value: "Trang chủ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "contacts", value: "Danh bạ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "notify", value: "Thông báo", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "account",
        value: "Tài khoản",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "message", value: "Lời nhắn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "medicine",
        value: "Dặn thuốc",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "absent", value: "Xin nghỉ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "album", value: "Album", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {
        key: "evaluate",
        value: "Nhận xét",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "attendance",
        value: "Điểm danh",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "studentFeesNew",
        value: "Quản lý học phí",
        file: null, url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {
        key: "studentFees",
        value: "Học phí",
        file: null,
        url: "",
        dialogImg: false,
        showFile: true,
        disabled: false, urlKeep: "", id: null
    },
    {key: "learn", value: "Học tập", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "menu", value: "Thực đơn", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "video", value: "Video", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "camera", value: "Camera", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "news", value: "Tin tức", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "health", value: "Con yêu", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "feedback", value: "Góp ý", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "homeInfoKid", value: "Thông tin học sinh", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},
    {key: "headerContactParent", value: "Header màn danh bạ", file: null, url: "", dialogImg: false, showFile: true, disabled: false, urlKeep: "", id: null},

];
export const listTicketRequest = [
    {
        id: 1,
        name: "Học tập và rèn luyện",
        url: "",
        numberPicture: 0,
    },
    {
        id: 2,
        name: "Ăn uống",
        url: "",
        numberPicture: 0,
    },
    {
        id: 3,
        name: "Ngủ nghỉ",
        url: "",
        numberPicture: 0,
    },
    {
        id: 4,
        name: "Vệ sinh",
        url: "",
        numberPicture: 0,
    },
    {
        id: 5,
        name: "Sức khỏe",
        url: "",
        numberPicture: 0,
    },
    {
        id: 6,
        name: "Nhận xét chung",
        url: "",
        numberPicture: 0,
    },
    {
        id: 7,
        name: "Nhận xét tuần",
        url: "0",
        numberPicture: 0,
    },
    {
        id: 8,
        name: "Nhận xét tháng",
        url: "",
        numberPicture: 0,
    },
    {
        id: 9,
        name: "Nhận xét định kỳ",
        url: "",
        numberPicture: 0,
    },
]

export const listDisplayTicket = [
    {
        id: 1,
        name: "Học tập và rèn luyện",
    },
    {
        id: 2,
        name: "Ăn uống",
    },
    {
        id: 3,
        name: "Ngủ nghỉ",
    },
    {
        id: 4,
        name: "Vệ sinh",
    },
    {
        id: 5,
        name: "Sức khỏe",
    },
    {
        id: 6,
        name: "Nhận xét chung",
    },
    {
        id: 7,
        name: "Nhận xét tuần",
    },
    {
        id: 8,
        name: "Nhận xét tháng",
    },
    {
        id: 9,
        name: "Nhận xét định kỳ",
    },
]